<template>
  <div>
    <vx-card>
      <div class="flex flex-wrap justify-between items-center">
        <div class="mb-4 md:mb-0 mr-4">
          <div class="flex items-center">
            <vs-button class="mr-3" color="primary" type="border" icon-pack="feather" icon="icon-refresh-cw" @click="getData(true)"></vs-button>
            <vs-switch class="mr-3" v-model="showFilter" icon-pack="feather" vs-icon-on="icon-eye" vs-icon-off="icon-eye-off">
              <span slot="on">Filter</span>
              <span slot="off">Filter</span>
            </vs-switch>
          </div>
        </div>
        <div class="flex flex-wrap items-center sm:justify-between w-full sm:w-auto">
          <div class="w-full sm:w-auto flex">
            <vs-input icon-pack="feather" icon="icon-search" class="w-full" placeholder="Cari Unit" v-model="table.search" @keyup="onSearch"/>
          </div>
        </div>
      </div>

      <vs-divider v-if="showFilter"></vs-divider>

      <div class="vx-row" v-if="showFilter">
        <div class="vx-col sm:w-3/12 w-full">
          <label class="ml-1 text-xs">Proyek</label>
          <v-select :options="proyeks" @input="onChangeProyek" label="nama" :reduce="item => item.id" :clearable="false" v-model="filter.id_proyek"/>
        </div>
        <div class="vx-col sm:w-3/12 w-full">
          <label class="ml-1 text-xs">Kategori</label>
          <v-select :options="propertiKategoris" @input="onChangeKategori" label="nama" :reduce="item => item.id" :clearable="true" v-model="filter.id_properti_kategori"/>
        </div>
        <div class="vx-col sm:w-3/12 w-full">
          <label class="ml-1 text-xs">Tipe</label>
          <v-select :options="propertiTipes" @input="onChangeTipe" label="nama" :reduce="item => item.id" :clearable="true" v-model="filter.id_properti_tipe"/>
        </div>
        <div class="vx-col sm:w-3/12 w-full">
          <label class="ml-1 text-xs">Cluster</label>
          <v-select :options="clusters" @input="onChangeCluster" label="nama" :reduce="item => item.id" :clearable="true" v-model="filter.id_cluster"/>
        </div>
      </div>
    </vx-card>

    <!--loading-->
    <vs-progress indeterminate color="primary" :height="1" :class="{ 'invisible': !table.loading }"/>

    <!--empty-->
    <div class="w-full mt-10" v-if="isEmpty && !table.loading">
      <div class="flex items-center justify-center">
        <img class="w-64 h-auto m-4 opacity-75" src="@/assets/svg/under_construction.svg" alt="Empty"/>
      </div>
      <p class="text-center opacity-75">Tidak ada data.</p>
    </div>

    <!--data-->
    <div class="vx-row">
      <div v-for="item in table.rows" :key="item.id" class="vx-col mb-3 w-full sm:w-1/3 cursor-pointer">
        <vx-card @click="goToListSpk(item.id)">
          <div class="flex">
            <div class="w-full">
              <h5>Unit {{ item.no_unit }}</h5>
              <span class="text-sm" :class="item.total_bobot_completed < 100 ? 'text-primary' : 'text-success'">Bobot Tercapai: {{ item.total_bobot_completed }}%</span>
            </div>
            <div>
              <span v-if="item.total_spk > 0" class="whitespace-no-wrap px-3 py-1 text-sm leading-none text-white rounded-lg bg-primary text-italic">{{ item.total_spk }} SPK</span>
              <span v-else class="whitespace-no-wrap px-3 py-1 text-sm leading-none text-italic">{{ item.total_spk }} SPK</span>
            </div>
          </div>
        </vx-card>
      </div>
    </div>

    <!--load more-->
    <div class="w-full" v-if="table.rows.length < table.totalItems">
      <div class="flex items-center justify-center">
        <vs-button @click="loadMore" class="py-3 px-2 font-medium text-lg" :disabled="table.loading" type="flat" color="dark" icon-pack="feather" icon="icon-chevrons-down">
          <span v-if="table.loading" class="animate-pulse">Loading ...</span>
          <span v-if="!table.loading">Load More</span>
        </vs-button>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import vSelect from 'vue-select'
import PropertiKategoriRepository from '@/repositories/master/properti-kategori-repository'
import ProyekRepository from '@/repositories/master/proyek-repository'
import PropertiTipeRepository from '@/repositories/master/properti-tipe-repository'
import PropertiClusterRepository from '@/repositories/master/cluster-repository'
import ProgressUnitRepository from '@/repositories/proyek/progress-unit-repository'

export default {
  name: 'ProgressUnitAsTeknik',
  components: {
    'v-select': vSelect
  },
  mounted () {
    this.getProyek()
    this.getCluster()
  },
  data () {
    return {
      filter: {},
      showFilter: true,
      table: {
        loading: false,
        page: 1,
        limit: 36,
        totalItems: 0,
        sort: '',
        search: '',
        rows: []
      },
      proyeks: [],
      propertiKategoris: [],
      propertiTipes: [],
      clusters: []
    }
  },
  computed: {
    totalPages () {
      return Math.ceil(this.table.totalItems / this.table.limit)
    },
    isEmptyFilter () {
      return _.isEmpty(this.filter)
    },
    isEmpty () {
      return this.table.rows.length < 1
    }
  },
  methods: {
    onChangeProyek () {
      this.propertiKategoris = []
      this.propertiTipes = []
      this.filter.id_properti_kategori = null
      this.filter.id_properti_tipe = null
      this.getData(true)
      this.getPropertiKategori()
    },

    onChangeKategori () {
      this.propertiTipes = []
      this.filter.id_properti_tipe = null
      this.getData(true)
      this.getPropertiTipe()
    },

    onChangeTipe () {
      this.getData(true)
    },

    onChangeCluster () {
      this.getData(true)
    },

    getData (isReplaceData = false) {
      this.table.loading = true
      const params = (({ page, limit, search, sort }) => ({ page, limit, search, sort }))(this.table)

      // additional params
      params.withTotalSpk = true
      params.withTotalBobotCompleted = true

      // filter
      params.filter = JSON.stringify({
        only_authorized_user: 1,
        ...this.filter
      })

      ProgressUnitRepository.getPropertiUnit(params)
        .then(response => {
          this.table.totalItems = response.data.data.total
          if (isReplaceData) {
            this.table.rows = response.data.data.data
          } else {
            _.each(response.data.data.data, item => this.table.rows.push(item))
          }
          if (this.table.page > response.data.data.last_page) {
            this.table.page = response.data.data.last_page
          }
        })
        .catch(error => {
          console.log(error)
          this.notifyError('Terjadi kesalahan.')
        })
        .finally(() => {
          this.table.loading = false
        })
    },

    getProyek () {
      const params = {
        filter: JSON.stringify({
          only_authorized_user: 1
        })
      }
      ProyekRepository.getAll(params)
        .then(response => {
          this.proyeks = response.data.data
        })
        .catch(error => {
          console.log(error)
        })
    },

    getPropertiKategori () {
      const idProyek = this.filter.id_proyek
      PropertiKategoriRepository.getPropertiKategoriByProyek(idProyek)
        .then(response => {
          this.propertiKategoris = response.data.data
        })
        .catch(error => {
          console.log(error)
        })
    },

    getPropertiTipe () {
      const idKategori = this.filter.id_properti_kategori
      PropertiTipeRepository.getAllPropertiTipeByKategori(idKategori)
        .then(response => {
          this.propertiTipes = response.data.data
        })
        .catch(error => {
          console.log(error)
        })
    },

    getCluster () {
      PropertiClusterRepository.getAllCluster()
        .then(response => {
          this.clusters = response.data.data
        })
        .catch(error => {
          console.log(error)
        })
    },

    onFilter () {
      this.getData()
    },

    loadMore () {
      this.table.page++
      this.onPageChange()
    },

    onPageChange () {
      this.getData()
    },

    onSearch: _.debounce(function (e) {
      this.table.page = 1 // reset page
      this.getData(true)
    }, 500),

    goToListSpk (idPropertiUnit) {
      this.$router.push({ name: 'proyek.progressUnit.asTeknik.listSpk', params: { idPropertiUnit: idPropertiUnit } })
    }
  }
}
</script>
